<template>
  <div>
    <BlockHeader
      :label="label"
      :collapsable="collapsable"
      :collapsed="collapsed"
      :bg-class="bgClass"
      @toggle-block-collapse="collapsed = !collapsed"
    >
      <slot name="header">
        {{ label }}
      </slot>
    </BlockHeader>

    <div
      v-if="!(collapsed && collapsable)"
      :class="blockClass"
    >
      <slot />
    </div>
  </div>
</template>
<script>
const BlockHeader = () => import('./BlockHeader')
export default {
  name: 'BlockGroup',

  components: {
    BlockHeader
  },

  props: {
    label: {
      type: String,
      required: true
    },

    level: {
      type: Number,
      default: 1
    },

    closed: {
      type: Boolean,
      default: true
    },

    collapsable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      collapsed: true
    }
  },

  computed: {

    bgClass () {
      if (this.level > 1) return 'bg-gray-800 text-purple-400'
      return 'bg-gray-900 text-yellow-400'
    },

    blockClass () {
      if (this.level > 1) return 'border-l-8 border-gray-700'
      return null
    }
  },

  mounted () {
    this.collapsed = this.closed
  }

}
</script>
